<template>
  <navbar
    position="fixed"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
    class="fcx-navbar"
  >
    <template>
      <router-link
        v-popover:popover1
        class="navbar-brand"
        to="/"
      >
        <img :src="`${PublicPath}img/logo/FCX-Logo.png`"  height="50" alt="">
      </router-link>
    </template>

    <template slot="navbar-menu">
      <li class="nav-item about">
        <nav-link
          class="nav-link btn btn-fcx-blue"
          to="/about"
        >
          <i class="fa fa-star" aria-hidden="true"></i> About FCX
        </nav-link>
      </li>
    </template>

    <template slot="navbar-menu">
      <li class="nav-item about">
        <nav-link
          class="nav-link btn btn-fcx-orange"
          to="/contact"
        >
          <i class="fa fa-life-ring" aria-hidden="true"></i> Contribute
        </nav-link>
      </li>
    </template>

    <template slot="navbar-menu" v-if="CurrentLocalUserX && CurrentLocalUserX.email">
      <li class="nav-item">
        <nav-link
          class="nav-link btn btn-primary"
          to="/saved-search"
        >
          <i class="fa fa-star" aria-hidden="true"></i> Search
        </nav-link>
      </li>
      <li class="nav-item">
        <button class="dropdown-item nav-link btn btn-default" @click="logout">
          <i class="fa fa-power-off" aria-hidden="true"></i> Logout
        </button>
      </li>
    </template>
    <template slot="navbar-menu" v-else>
      <li class="nav-item">
        <nav-link
          class="nav-link btn btn-fcx-yellow"
          to="/signup"
        >
          <i class="fa fa-mobile" /> Register
        </nav-link>
      </li>
      <li class="nav-item">
        <nav-link
          class="nav-link btn btn-primary"
          to="/login"
        >
          <i class="fa fa-user" /> Login
        </nav-link>
      </li>
    </template>
  </navbar>
</template>

<script>
  import { DropDown, Navbar, NavLink } from '@/components';
  import { Popover } from 'element-ui'
  export default {
    name: 'MainNavbar',
    components: {
      DropDown,
      Navbar,
      NavLink,
      [Popover.name]: Popover
    },
    props: {
      transparent: Boolean,
      colorOnScroll: Number,
    },
    methods: {
      logout() {
        this.$store.dispatch('LOGOUT').then(() => {
          this.$router.push({name: 'login'})
        })
      }
    }
  }
</script>

<style scoped>
li.nav-item.about {
  margin-right: 30px;
}
@media (max-width: 991px) {
  li.nav-item.about {
    margin-right: 0;
  }
}
</style>
